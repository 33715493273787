import React, { useEffect, useRef, useState } from 'react';
import css from './CustomListingCard.module.css';

import classNames from 'classnames';
import f1 from '../../assets/banner1.png';
import NamedLink from '../NamedLink/NamedLink';
import compare from '../../assets/compare.PNG';
import heart from '../../assets/heart.png';
import mag from '../../assets/magnifying_glass.png';
import { createSlug } from '../../util/urlHelpers';
import { Button } from '..';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createCart, createWishlist, removeCartItem } from '../../containers/ListingPage/ListingPage.duck';

//

const CustomSearchListingCardComponent = props =>{
    const activeClassName = 'my-active-class';
    const {showSideForm,
        handleHideForm,
        listings,
        onCreateCart,
        wishlistId,
        currentUser,
        cartData,
        createWishlistInProgress,
        addToWishlist,
        
    } = props;

    const [showCartBtn,setShowCartBtn] = useState(false);
    const searchColorsArr = localStorage.getItem("searchColor") !== null ? localStorage.getItem("searchColor").split(" "):"";
    const selectedColors = listings?.attributes?.publicData?.selectedColors;
    const imageColors = listings?.attributes?.publicData?.imageColors;
    let currentImgId = "";

    let found = false;
    selectedColors !== undefined && selectedColors.map((val,key)=>{
      //if(found)return;
      const currentColor = val[Object.keys(val)[0]];
      if(searchColorsArr.includes(currentColor.toLowerCase())){
         currentImgId = imageColors[Object.keys(val)[0]];
        found = true;
        console.log(currentImgId);
      }
    
    })

    const stockCount = listings?.currentStock?.attributes?.quantity;
    const stockVal = stockCount === undefined?"Not in stock": stockCount + " in stock";

    const title = listings?.attributes?.title;
    const description = listings?.attributes?.description;
    const price = listings?.attributes?.price?.amount;
    const colors = listings?.attributes?.publicData?.color;
    let id  = listings?.id?.uuid;
    const img = currentImgId !== undefined && currentImgId !== "" && currentImgId !== null?currentImgId : listings?.images[0]?.attributes?.variants["my-variant"]?.url;
    if(id === null || id === undefined){
        id="abcuyuuyu";
    }

    const isWishListItem = wishlistId != undefined && wishlistId.includes(id)?true:false;
    if(id === null || id === undefined){
        id="abcuyuuyu";
    }


    if(title === null || title === undefined){return ""};

    const handleAddToCart = (event,listingId,title,description,price,quantity,img)=>{

        //Check if exist
        const exist = cartData !== undefined && cartData.filter(item=> item.id === listingId);
        if(exist && exist.length !== 0){
          //handleHideShoppingCart();
          return;
        };
        const listingItem = {
                              id:listingId,
                              img:img,
                              title:title,
                              desc:description,
                              price:price,
                              quantity:quantity
                            };
        
        const newData = [listingItem]
        
        //setCartData(newData);
        onCreateCart(newData);
        handleHideShoppingCart();
      }

      const handleAddToWishlist = (event,listingId,title,description,price,quantity,img)=>{
        const listingItem = {
                              id:listingId,
                              img:img,
                              title:title,
                              desc:description,
                              price:price,
                              quantity:quantity
                            };
        
        const newData = [listingItem]
        
        //setCartData(newData);
        addToWishlist(newData);
        
      }
      

      const handleRemoveItem = (event,id)=>{
        removeCartItem(id);
    }

const handleShowCartBtn = (event)=>{
  setShowCartBtn(true);
  console.log("dddddddddddddddd");
}

const handleHideCartBtn = (event)=>{
  setShowCartBtn(false);
  console.log("ooooooooooooo");
}


  return (
    
    <>
         <div className={css.rowItem}>
                                    <div className={classNames(css.rowItemSub,css.roundEdge)}>
                                        
                                        <div className={css.imgCon}>
                                        <NamedLink 
                                            name="ListingPage"
                                            className={css.loginLink}
                                            params={{ id: id, slug: createSlug({title}) }}
                                        >
                                            <img className={css.resize} src={img} />
                                        </NamedLink>
                                            
                                        </div>
                                        <div className={css.addToCart}>

                                          {showCartBtn?

                                            <Button
                                            onMouseOut={handleHideCartBtn}
                                            className={css.loginLink}
                                            onClick={event=>handleAddToCart(event,id,title,description,price,1,img)}
                                            >
                                              <svg className={css.svgColor} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                              </svg> 
                                            </Button>

                                            
                                            :
                                            <Button
                                              onMouseOver={handleShowCartBtn}
                                              
                                              className={css.loginLink}
                                              onClick={event=>handleAddToCart(event,id,title,description,price,1,img)}
                                            >
                                              ADD TO CART 
                                            </Button>
                                        }
                                            
                                            
                                        </div>
                                        <div className={css.addSide}>
                                            <NamedLink name="LoginPage" className={css.loginLink}>
                                                <img className={css.resizeIcon} src={compare} />
                                            </NamedLink>
                                            <NamedLink name="LoginPage" className={css.loginLink}>
                                                <img  src={mag} />
                                            </NamedLink>
                                            <Button inProgress={createWishlistInProgress}  onClick={event=>handleAddToWishlist(event,id,title,description,price,1,img)} className={css.loginLink}>

                                                {isWishListItem?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
                                                    </svg>
                                                    :
                                                    <img  src={heart} />
                                                }


                                            </Button>
                                        </div>
                                       
                                    </div>
                                    <div className={css.caption}>
                                        <div className={css.flexRow}>
                                          {colors.map((v,k)=>{
                                              return(
                                                <div className={css.colorCircle} style={{backgroundColor:v}}></div>
                                              )
                                          })}
                                          
                                        </div>
                                        <NamedLink 
                                            name="ListingPage"
                                            className={css.loginLink}
                                            params={{ id: id, slug: createSlug({title}) }}
                                        >
                                            <p className={classNames(css.centerItem,css.shortText)}><b>{description}</b></p>
                                            <p className={classNames(css.centerTitile,css.shortText)}>{title}</p>
                                        </NamedLink>
                                        <p>${price}</p>
                                        <p>{stockVal}</p>
                                       
                                        <Button 
                                            
                                            className={css.quickView}
                                        >
                                            QUICK VIEW
                                        </Button>
                                       
                                    </div>
                                </div>
    </>
    
  );
};

const mapStateToProps = state => {


    const { currentUser } = state.user;
  let {
    cartData,
    createWishlistInProgress,
    createWishlistError,

  } = state.ListingPage;
  const wishlist = currentUser?.attributes?.profile?.privateData?.wishlist;
  const wishlistId = [];

  if(wishlist !== undefined && wishlist.length !== 0){
    wishlist.map((val,key)=>{
      wishlistId.push(val.id);
    });
  }
    
  const currentUserCart = currentUser?.attributes?.profile?.privateData?.cartData;
  cartData = cartData.length !== 0?cartData:currentUserCart;
  let quantity = 0;
  let prices = 0;

  // if(!isNaN(cartData) && cartData !== undefined && cartData.length !== 0 ){
  //   cartData.filter((item)=>{
  //   quantity += parseInt(item.quantity);
  //   prices += parseInt(item.price);
  // });
  // }

  return { 
    currentUser,
    cartData,
    wishlistId,
    createWishlistInProgress,
  };
 

    
  };
  
  const mapDispatchToProps = dispatch => ({
    onCreateCart: (cartData) =>dispatch(createCart(cartData)),
    onRemoveCartItem: (id) =>dispatch(removeCartItem(id)),
    addToWishlist: (data) =>dispatch(createWishlist(data)),
  });
  
  // Note: it is important that the withRouter HOC is **outside** the
  // connect HOC, otherwise React Router won't rerender any Route
  // components since connect implements a shouldComponentUpdate
  // lifecycle hook.
  //
  // See: https://github.com/ReactTraining/react-router/issues/4671
  const CustomSearchListingCard = compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(CustomSearchListingCardComponent);


export default CustomSearchListingCard;
